.playa{
    display: flex;
    position: absolute;
    width: 6vw;
    height: 2.5vw;
    /*background-image: url(./img/light/BG_LIGHT.png);
    background-size: contain;
    background-repeat: no-repeat;*/
    background: var(--light-bg);
    border: .065vw solid var(--black);
    color: var(--black);
    margin-top: 86vh;
    margin-left: 3vw;
    z-index: 2;
}
.playa::after{
    position: absolute;
    z-index: 3;
    content: '';
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1vw 1vw 0 0;
    border-color:  transparent var(--black) transparent  transparent;
}


body.dark .playa::after {
    /*background-image: url(./img/dark/BG_DARK.png);*/
    border-color:  transparent var(--white) transparent  transparent;
}
body.dark .setActiveBtn, body.dark .playa{
    background: var(--black);
    color: var(--white);
    border-color: var(--white);
}

.playa.active{
    width: 15vw;
}
.playa-img{
    position: absolute;
    bottom: -.5vw;
    left: -3vw;
    width: 5.5vw;
    pointer-events: none;
    transition-duration: 500ms;
}
.main-layer{
    display: none;
    align-items: center;
    margin-left: 2vw;
}
.playa.active .main-layer{
    font-size: 1vw;
    display: flex;
    width: 70%;
}
.playa.active .text-block{
    display: block;
}
.sound{
    margin: auto;
    margin-right: 1.7vw;
    width: 2vw;
    
}
.playa.active .sound{
    display: none;
}
.text-block{
    position: absolute;
    left: 2vw;
    display: none;
    width: 80%;
    color: var(--black);
}
body.dark .text-block{
    color: var(--white);
}
.text-block h3{
    font-size: 1vw;
    font-weight: 600;
}
.text-block p{
    font-size: .8vw;
    color: var(--accent);
    transition-duration: 500ms;
}
.btns{
    position: absolute;
    left: 6vw;
    display: flex;  
    padding: 2vw;
    transition-duration: 500ms;
}
.btn{
    display: flex;
    padding: 0 .5vw;
    align-items: center;
}


.prev, .next{
    width: 1.8vw;
}
.play{
    width: 2vw;
}


.setActiveBtn{
    position: absolute;
    top: -1vw;
    right: -1vw;
    background: var(--light-bg);
    color: var(--black);
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    justify-content: center;
    transition-duration: 500ms;
    border: .065vw solid var(--black);
    align-items: center;
    justify-content: center;
}

.setActiveBtn img{

    position: relative;
    width: .88vw;
    margin-left: 10%;
}

.playa.active .setActiveBtn img{
    margin-left: 0;
}

@media(max-width: 980px){
    .playa{
        width: 25vw;
        height: 13vw;
        margin-left: 9vw;
        margin-top: 80vh;
        border-width: .2vw;
    }
    .playa.active{
        width: 60vw;
    }

    .playa-img{
        width: 20vw;
        left: -10vw;
        bottom: -2vw;
    }

    .playa::after{
        border-width: 3vw 3vw 0 0;
    }
    .sound{
        width: 7vw;
        margin-left: 11vw;
    }
    .text-block{
        left: 10vw;
    }
    .text-block h3{
        font-size: 4vw;
    }
    .text-block p{
        font-size: 3vw;
    }
    .setActiveBtn{   
        top: -3.5vw;
        right: -3.5vw; 
        width: 8vw;
        height: 8vw;
        border-width: .2vw;
    }
    .btns{
        padding: 0vw 2.5vw;
        left: 33vw;
    }
    .btn{
        padding: 0 2vw;
    }
    .prev, .next{
        width: 7vw;
    }
    .play{
        width: 8vw;
    }
    .setActiveBtn img{
        width: 3.5vw;
        margin-top: .4%;
    }
    .playa.active .setActiveBtn img{
        margin-top: 0.6%;
    }
}