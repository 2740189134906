.select-btn{
    padding: .25vh .2vw;
    display: flex;
    align-items: center;
    transition-duration: 500ms;
    cursor: pointer;
}



.select-menu{
    z-index: 1;
}
.sBtn-text{
    padding: 0 0.15vw;
    
    font-size: .8vw;
}
.options{
    margin-top: -.1vh;
    position: fixed;
    background: var(--light-bg);
    border: 0.1vw solid var(--black);
    border-top: .3vw solid var(--black);
    display: none;
}
body.dark .options{
    background: var(--black);
    color: var(--white);
    border: 0.1vw solid var(--white);
    border-top: .3vw solid var(--white);
}
.select-menu.active .options{
    display: block;
}



.arrow{
    font-size: 0.45vw;
}
.select-menu.active .arrow{
    rotate: -180deg;
    transition: 0.3s;
}

.option{
    padding: .3vh 0;
    padding-right: 1.1vw;
    display: flex;
    cursor: not-allowed;
    align-items: center;
    text-align: center;
    transition-duration: 500ms;
}



@media (hover: hover){
    body.dark .option:hover{
        color: var(--black);
    }
    .option:hover{
        background: var(--accent);
    }
    .select-btn:hover{
        background: var(--black);
        color: var(--white);
    }
    
    body.dark .select-btn:hover{
        background: var(--white);
        color: var(--black);
    }
}


.option-text{
    align-items: center;
    justify-content: center;
    font-size: .6vw;
    padding: 0.3vh 0;
}
.option-text strong{
    font-size: 1vw;
    margin-right: .2vw;
}

@media(max-width: 980px){
    
    
    .select-btn{
        justify-content: center;
    }
    .select-menu{
        padding: 5vw;
        width: 100%;
        border-bottom: .1vw solid var(--accent);
    }
    .options, body.dark .options{
        position: relative;
        background: none;
        border-color: var(--accent);
    }
    .option-text{
        text-align: center;
        font-size: 5vw;
        padding: 4vw;
    }
    .option-text strong{
        display: none;
    }
    .sBtn-text{
        text-align: center;
        font-size: 6vw;
    }
    .option{
        justify-content: center;
        text-align: center;
        font-size: 6vw;
        border-bottom: .1vw solid var(--accent);
    }
    
    .arrow{
        font-size: 4.5vw;
    }
    
    
}