.about-us-page{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.about-us-page .left-column{
    width: 25%;
}
.about-us-page .center-column{
    width: 50%;
}
.about-us-page .right-column{
    width: 25%;
}

.about-us-list{
    display: flex;
    flex-direction: column;
    margin-top: 4vw;
    margin-left: 12vw;
}
.about-item{
    font-size: 1vw;
    color: var(--accent);
    margin-bottom: 1.8vw;
    cursor: pointer;
}

.about-us-main-content{
    display: flex;
}


.about-swiper{
    position: relative;
    width: 100%;
    height: 75vh;
    align-items: center;
    justify-content: center;
    left: 0;
    object-fit: contain;
}

.about-swiper::after{
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 10%;
    z-index: 1;
    background: linear-gradient(rgba(0,0,0,0) 0%, var(--white) 100%);
}

body.dark .about-swiper::after{
    background: linear-gradient(rgba(0,0,0,0) 0%, var(--dark-mode) 100%);
}
.about-swiper::before{
    position: absolute;
    content: '';
    top: 0;
    width: 100%;
    height: 5%;
    z-index: 2;
    background: linear-gradient(var(--white) 0%, rgba(0,0,0,0) 100%);
}

body.dark .about-swiper::before{
    background: linear-gradient(var(--dark-mode) 0%,  rgba(0,0,0,0) 100%);
}
.about-swiper .swiper-slide{
    margin-top: 2.5vw;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}
.authors-text-block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vw;
}

.authors-text-block h1{
    color: var(--accent);
    text-align: center;
    font-size: 1.3vw;
    margin: 1vw 0;
}
.authors-text-block p{
    font-size: .8vw;
}
.aiden, .fugazi{
    width: 40%;
    text-align: center;
}

.about-text-block{
    font-size: 1vw;
    text-align: justify;
    width: 100%;
    line-height: 1.5vw;
}

.about-text-block h1{
    text-align: center;
    font-size: 1.9vw;
    font-weight: 600;
    color: var(--accent);
    margin-bottom: 4vw;
}

.inner-text-block{
    width: 80%;
    margin: 0 auto;
}

.inner-text-block h2{
    color: var(--accent);
    font-size: 1.5vw;
    margin-bottom: 1vw;
}
.inner-text-block p{
    margin-bottom: 2vw;
}

.about-swiper-scrollbar{
    background: var(--black);
    right: 8vw;
    margin: 0 auto;
    border-radius: 0;
    width: .1vw;
}

.about-swiper-scrollbar .swiper-scrollbar-drag{
    width: .3vw;
    background: var(--black);
    border-radius: 0;
}

body.dark .about-swiper-scrollbar {
    background: var(--white);
    
}
body.dark .about-swiper-scrollbar .swiper-scrollbar-drag{

    background: var(--white);
}

@media(max-width: 980px){
    
    .page-title .left-column{
        width: 100vw;
    }
    
    .about-us-main-content{
        width: 100%;
        margin-top: 35vw;
        flex-direction: column;
    }
    .about-us-main-content .right-column{
        display: none;
    }
    .about-us-page .left-column, .about-us-main-content .center-column, .about-us-page .right-column{
        width: 100%;
        margin: 0 auto;
    }
    .about-us-list{
        margin: auto;
        flex-direction: row;
        gap: 4vw;
        margin-bottom: 6vw;
    }
    .about-item{
        font-size: 4vw;

    }
    .authors-text-block{
        margin-bottom: 8vw;
    }
    .authors-text-block h1{
        font-size: 4vw;
    }
    .authors-text-block p{
        font-size: 2.5vw;
    }
    .about-text-block{
        font-size: 4vw;
        line-height: 5vw;
    }
    .about-text-block h1{
        font-size: 5vw;
        margin-bottom: 8vw;
    }
    .about-swiper{
        margin: auto;
        z-index: 0;
        width: 90%;
        height: 125vw;
    }
    .inner-text-block h2{
        font-size: 4.5vw;
        margin: 5vw 0;
    }
}