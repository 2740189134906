

.left-column{
    display: flex;
    justify-content: space-between;
    width: 65%;
}

.square{
    height: 1vw;
    width: 0.8vw;
    border-bottom: .1vw solid var(--black);
}
.center-column{
    width: 10%;
    display: flex;
    justify-content: space-between;
}

.right-column{
    width: 25%;
    display: flex;
    justify-content: space-between;
}
.left-column .square{
    border-right: .15vw solid var(--black);
}
.center-column .square{
    width: .7vw;
}
.right-column .square{
    border-left: .15vw solid var(--black);
}
.main-content{
    display: flex;
    width: 100%;
    height: 75vh;
}

.slider{
    margin-top: 2vw;
    width: 104.5%;
    left: -3vw;
    max-height: 31.5vw;
}
.swiper-slide{
    width: 105%;
}
.main-content .center-column{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .3vw;
    position: relative;
}

.main-content .left-column{
    position: relative;
    flex-direction: column;
}
.main-item{
    width: 100%;
    height: 31vw;
    position: relative;
}
.main-img{
    display: inline-block;
	position: relative;    
	line-height: 0;
    width: 100%;
    overflow: hidden;
}
.main-img img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 31vw;
    transition-duration: 500ms;
}
.main-img::after{
    content: "";
	display: block;
	width: 100%;
	height: 100%;    
	position: absolute;
    inset: 0; 
	background: transparent;
	background-image: linear-gradient(235deg, rgba(0,0,0,0) 45%, var(--white) 100%);   
}

body.dark .main-img::after{
	background-image: linear-gradient(235deg, rgba(0,0,0,0) 45%, var(--black) 100%);  

}

.main-title{
    margin-left: 3.5vw;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    margin-top: 4%;
    transition-duration: 500ms;
}
.main-title h1{
    font-size: 2vw;
    font-weight: 500;
}
.main-title-text-block{
    margin-top: 21vw;
}
.main-title-text-block p{
    font-size: .9vw;
    color: var(--accent);
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets{
    
    margin: 0 4vw;
}
.swiper-pagination-bullet{
    --swiper-pagination-bullet-width: 0.95vw;
    --swiper-pagination-bullet-height: 0.9vw;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: transparent;
    border: .2vw solid var(--accent);
    transition-duration: 500ms;
}
.swiper-pagination-bullet-active{
    
    --swiper-pagination-color: var(--black);
    border: .2vw solid var(--black);
}

body.dark .swiper-pagination-bullet-active{
    
    --swiper-pagination-color: var(--white);
    border: .2vw solid var(--white);
}


.main-content .right-column{
    justify-content: center;
}

.right-text-block{
    margin: auto;
    width: 100%;
    padding-right: 3vw;
    font-size: .9vw;
}
.right-text-block h1{
    font-size: 1.25vw;
    color: var(--accent);
    text-align: center;
    margin-bottom: 2vw;
    font-weight: 600;
}
.right-text-block p{
    
    margin-bottom: 1vw;
    line-height: 1.5vw;
}

@media(max-width: 980px){
    .future-world{
        display: flex;
        flex-direction: column;
        margin-top: 4vh;
        height: 170vw;
    }
    .left-column, .center-column, .right-column{
        width: 100%;
    }
    .slider{
        max-height: 70vw;
    }
    .main-item, .main-img img{
        height: 70vw;
    }
    .main-title h1{
        font-size: 5vw;
        font-weight: 500;
    }
    .main-title-text-block{
        margin-top: 45vw;
    }
    .main-title-text-block p{
        font-size: 3vw;
        color: var(--accent);
    }
    
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets{

        display: flex;
        margin: 10vw 40%;
    }
    .swiper-pagination-bullet{
        --swiper-pagination-bullet-width: 3.9vw;
        --swiper-pagination-bullet-height: 4vw;
        border: .8vw solid var(--accent);
        border-radius: 50%;
        transition-duration: 500ms;
    }
    .right-text-block{
        width: 90%;
        margin-top: 20vw;
        font-size: 4vw;
    }
    .right-text-block h1{
        font-size: 5vw;
    }
    .right-text-block p{
        
        margin-bottom: 1vw;
        line-height: 5vw;
    }
}