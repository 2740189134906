

.footer{
    margin-top: auto;
    height: 5vw;
}
.footer-container{
    display: flex;
    position: relative;
}
.footer-container .left-column, .footer-container .right-column, .footer-container .center-column{
    border-top: .1vw solid var(--border-black);
}

.barcode{
    position: absolute;
    top: 0;
    left: 15vw;
    width: 4%;
    height: 20%;
}

.footer-container .square{
    border-top: .1vw solid var(--black);
    border-bottom: 0;
}

.footer-text-block{
    margin-top: 1.5vw;
}
.footer-text-block span{
    font-size: .5vw;
    margin-right: 2vw;
}
.footer-text-block a, .footer-text-block{
    color: var(--dark-bg);
}


.footer-container .right-column .footer-text-block {
    text-align: right;
    display: flex;
    flex-direction: column;
    margin-top: 1vw;  
}



.footer-container .right-column .footer-text-block span{
    margin: 0;
}

body.dark .footer-container .left-column, body.dark .footer-container .center-column, body.dark .footer-container .right-column{
    border-color: var(--border-white);
}

body.dark .footer-text-block a, body.dark .footer-text-block{
    color: var(--light-bg);
}
    

@media(max-width: 980px){
   .footer{
        margin-top: none;
   }
    .footer-container{
        flex-direction: column;
    }
    .footer-container .left-column, .footer-container .right-column, .footer-container .center-column{
        width: 100%;
        justify-content: center;
    }
    .footer-text-block{
        margin-top: 8vw;
    }
    .footer-text-block span{
        font-size: 3vw;
    }
    .barcode{
        width: 10%;
        height: 3vw;
    }
    .footer-container .right-column, .footer-container .center-column{
        border: none;
    }
    .footer-container .right-column .footer-text-block{
        text-align: center;
        margin-top: 5vw;
    }
}