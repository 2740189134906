@import url(./reset.css);

@font-face{
    font-family: 'Zekton Rg';
    src: url(./fonts/Zekton/zekton\ rg.ttf);
}
:root{
    --accent: #feb302;
    --dark-bg: #8b8b8b;
    --dark-mode: #1b1b1b;
    --light-bg: #999999;
    --black: #0a0a0a;
    --white: #f4f4f4;
    --border-black: rgba(10, 10, 10, .5);
    --border-white: rgba(184, 184, 184, .5);
}

html, body{
    color: var(--black);
    background-image: url(./img/bg/light-bg.png);
    background-repeat: no-repeat;
    background-size: 100.5%;
    overflow: hidden;
    font-family: 'Zekton Rg';
    width: 100vw;
    height: 100vh;
    font-weight: 500;
}






.container{
    padding: 0 2vw;
    margin: 0 auto;
    box-sizing: border-box;
}
.page-title{
    display: flex;
    font-size: .8vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    
    border-bottom: .1vw solid var(--border-black);
}
.page-title-text span{
    color: var(--accent)
}
body.dark .page-title{
    border-color: var(--border-white);
}
.App{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

body.dark{
    color: var(--white);
    background-image: url(./img/bg/dark-bg.png);
}

body.dark .square{
    border-color: var(--white);
}

@media(max-width: 980px){
    body{
        background-size: cover;
        overflow-y: scroll;
    }
    .container{
        padding: 0;
    }
    .page-title{
        top: 11.5vw;
        padding: 6vw 14vw;
        align-items: center;
        width: 100%;
        height: 2vw;
        position: fixed;
        font-size: 5vw;
        border: none;
        z-index: 1;
        
        background: var(--white);
    }
    body.dark .page-title{
        
        background: var(--dark-mode);
    }
    .page-title .left-column{
        width: 100vw;
    }
    .page-title .right-column, .page-title .center-column{
        display: none;
    }
    .square{
        display: none;
    }
    .main-content{
        z-index: 0;
        margin-top: 15vw;
        position: relative;
        flex-direction: column;
    }
    body::before{
        position: absolute;
        content: '';
        top: 23vw;
        width: 100%;
        height: 5%;
        z-index: 1;
        background: linear-gradient(var(--white)  0%, rgba(0,0,0,0)  70%);
    }

    body.dark::after{
        
        background: linear-gradient(rgba(0,0,0,0) 0%, var(--dark-mode) 100%);
    }
    body.dark::before{
        
        background: linear-gradient(var(--dark-mode) 5%, rgba(0,0,0,0)  100%);
    }
}