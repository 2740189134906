/* NAV */

.nav{
    background: var(--light-bg);
    width: 100%;
    min-height: 3vw;
    align-items: center;
    z-index: 2;
    position: relative;
}

.nav-container{
    padding: .9vw 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.nav-logo{
    width: 50%;
    color: var(--black);
    display: flex;
    margin-right: 3vw;
    z-index: 1;
    align-items: center;
}
.logo-img{
    width: 15%;
    z-index: 1;
}
.logo-text{
    margin-left: 1vw;
    font-size: .7vw;
    z-index: 1;
}
.logo-text span{
    color: var(--accent);
    z-index: 1;
}
.nav-row{
    display: flex;
    align-items: center;
    gap: 1vw;
    z-index: 1;
}

.dark-btn{
    align-items: center;
    justify-content: center;
    display: flex;
    width: .7vw;
}



.nav-links{

    display: flex;
    align-items: center;
    font-size: .8vw;
    position: relative;
    z-index: 1;
    height: 1.3vw;
}

.nav-links-tab{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-size: contain;
}

.nav-link{
    padding: .25vh .4vw;
    color: var(--black);
    text-align: center;
    z-index: 1;
    transition-duration: 500ms;
    cursor: not-allowed;
}
.nav-link:hover, .nav-link.active{
    background: var(--black);
    color: var(--white);
}


.about{
    position: relative;
    padding: .25vh .45vw;
    color: var(--black);
    text-align: center;
    z-index: 1;
    width: 4.8vw;
    align-items: center;
    transition-duration: 500ms;
    z-index: 0;
}

.about::after{
    z-index: 1;
    content: '';
    position: absolute;
    top: -.094vw;
    left: -.08vw;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: .6vw .7vw 0 0;
    border-color: var(--light-bg) transparent transparent transparent;
}

body.dark .about::after{

    border-color: var(--black) transparent transparent transparent;
}

.about:hover, .about.active{
    background: var(--black);
    color: var(--white);
}

body.dark .about:hover, body.dark .about.active{
    
    background: var(--white);
    color: var(--black);
}

.fw{
    
    width: 7vw;
}
.connect{
    width: 4.7vw;
    padding: .4vh .5vw;
    color: var(--black);
    z-index: 1;
    cursor: not-allowed;
}

.social-links{
    align-items: center;
    justify-content: center;
    display: flex;
    gap: .5vw;
    position: relative;
}
.join{
    position: absolute;
    margin-top: 7vh;
    align-items: center; 
    z-index: 1;
    animation: move-up-down;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes move-up-down{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(.2vw);
    }
    100%{
        transform: translateY(0);
    }
}

.join-img{
    position: relative;
}
.join-text{
    inset: 0;
    margin-top: 1.5vh;
    margin-left: 1vh;
    position: absolute;
    color: var(--white);
    font-size: .8vw;
}
body.dark .join-text{
    color: var(--black);
}
.social-link{
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1;
    width: 1.8vw;
}
.x{
    width: 1.3vw;
    z-index: 1;
}
.nav-btn{
    display: none;
}

body.dark .nav{
    background-color: var(--black);
}

body.dark .nav-logo, body.dark .nav-link, body.dark .about{
    color: var(--white);
}

body.dark .nav-link:hover, body.dark .nav-link.active{
    background: var(--white);
    color: var(--black);
}



@media(max-width: 980px){
    .nav{
        position: fixed;
    }
    .nav-logo{
        width: 100%;
    }
    .logo-img{
        width: 30%;
        padding: 1vw 0;
    }
    .logo-text{
        font-size: 3vw;
        margin-left: 3.5vw;
    }

    .nav-links-tab, .join{
        display: none;
    }

    .nav-links{
        height: auto;
    }
    .nav-btn{
        display: block;
        position: absolute;
        width: 8vw;
        top: 17vw;
        left: 3.5vw;
        z-index: 5;
    }
    
    .nav-row{
        display: none;
    }
    .nav-row.active{
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 13.5vw;
        right: 0vw;
        /*background-image: url(./../../img/bg/light-bg.png);*/
        backdrop-filter: blur(5vw) brightness(1.2) contrast(.7);
        padding: 10vh 2vw;
        overflow-y: scroll;
    }
    body.dark .nav-row.active{
        backdrop-filter: blur(5vw) brightness(.5);
    }
    .nav-row.active .nav-links{
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: 6vw;
        width: 100%;
    }
    

    .nav-link, .connect{
        width: 100%;
        padding: 5vw 0;
        text-align-last: center;
        border-bottom: .1vw solid var(--accent);
    }
    .about{
        width: 100%;
        text-align-last: center;
        padding: 5vw 0;
        border-bottom: .1vw solid var(--accent);
        height: auto;
        margin-top: -1vw;
    }
    .connect{
        background: var(--accent);
    }
    .dark-btn{
        width: 5%;
        align-self: center;
        margin-bottom: 5vw;
    }
    .social-links{
        margin-top: 5vw;
        width: 100%;
        gap: 5vw;
    }
    .social-link{
        
        width: 10vw;
    }
    .x{
        
        width: 8vw;
    }
}