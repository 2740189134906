



.terms-main-content{
    display: flex;
}

.terms-page .left-column, .terms-page .right-column{
    width: 25%;
}

.terms-swiper{
    position: relative;
    width: 100%;
    height: 75vh;
    align-items: center;
    justify-content: center;
    left: 0;
    object-fit: contain;
}



.terms-swiper::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 10%;
    z-index: 1;
    bottom: 0;
    background: linear-gradient(rgba(0,0,0,0) 0%, var(--white) 100%);
}
.terms-swiper::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 10%;
    z-index: 2;
    top: 0;
    background: linear-gradient(var(--white) 0%, rgba(0,0,0,0) 100%);
}

body.dark .terms-swiper::after{
    background: linear-gradient(rgba(0,0,0,0) 0%, var(--dark-mode) 100%);
}
body.dark .terms-swiper::before{
    background: linear-gradient( var(--dark-mode) 0%, rgba(0,0,0,0) 100%);
}
.terms-swiper .swiper-slide{
    width: 100%;
    height: auto;
    box-sizing: border-box;
}
.terms-list{
    display: flex;
    flex-direction: column;
    margin-top: 4vw;
    margin-left: 8vw;
}
.terms-item{
    font-size: 1vw;
    color: var(--accent);
    margin-bottom: 1.8vw;
    cursor: pointer;
}
.terms-page .center-column{
    width: 50%;
}


.terms .right-column{
    position: relative;
}
.terms-main-content .center-column{
    justify-content: center;
}

.terms-text{
    margin-top: 2.5vw;
    font-size: 1vw;
    text-align: justify;
    width: 100%;
    line-height: 1.5vw;
}
.terms-text_h1{
    text-align: center;
    color: var(--accent);
    font-size: 1.7vw;
    margin-bottom: 2vw;
}

.terms-text_p{
    font-size: 0.9vw;
    margin-bottom: 1vw;
}

.swiper-scrollbar{
    --swiper-scrollbar-bg-color: var(--black);
    --swiper-scrollbar-right: 8vw;
    --swiper-scrollbar-border-radius: 0;
    --swiper-scrollbar-size: .1vw;
    --swiper-scrollbar-sides-offset: 4vw;
}

.swiper-scrollbar .swiper-scrollbar-drag{
    width: .3vw;
    --swiper-scrollbar-drag-bg-color: var(--black);
}

body.dark .swiper-scrollbar {
    --swiper-scrollbar-bg-color: var(--white);
    
}
body.dark .swiper-scrollbar .swiper-scrollbar-drag{

    --swiper-scrollbar-drag-bg-color: var(--white);
}

.terms-not-swiper{
    display: none;
}

@media(max-width: 980px){
    .terms-main-content{
        width: 100%;
        margin-top: 32vw;
        flex-direction: column;
    }
    .terms-list{
        margin: 0 auto;
        width: 90%;
    }
    .terms-item{
        font-size: 4vw;
        margin-right: 4vw;
    }
    .terms-main-content .right-column{
        display: none;
    }
    .terms-page .left-column, .terms-page .center-column, .terms-page .right-column{
        width: 100%;
        margin: 0 auto;
    }
    .terms-page .center-column{
        flex-direction: column;
    }
    .terms-swiper{
        z-index: 0;
        width: 90%;
        height: auto;
        display: none;
    }
    .terms-swiper .swiper-slide{
        z-index: 0;
        width: 100%;
        
        display: none;
    }
    .terms-not-swiper{
        display: block;
        width: 90%;
        height: auto;
        margin: 0 auto;
    }
    .terms-text{
        z-index: 0;
    }
    .terms-text h1{
        font-size: 5vw;
        margin-top: 20vw;
        margin-bottom: 10vw;
        z-index: 0;
    }
    .terms-text p{
        font-size: 4vw;
        line-height: 5.5vw;
        margin-bottom: 8vw;
        z-index: 0;
    }
    .terms-swiper::after, .terms-swiper::before{
        z-index: 0;
        background: none;
    }
    
}